import { EventType } from './Event';
import { Genders } from './Genders';
import { Foods, Intolerances, Nutritionist, Objectives } from './PatientInfo';
import { ScoreType } from './Score';
import { UserTableDto } from './User';

const CALORIMETRY_TEST_ID = 'cf324ee1-5f8d-4241-9fef-800b44373c5d';
const CGM_TEST_ID = 'b67b059a-e23d-4bf1-92e6-21fbccca2d18';

export enum MedicalTestTypeValue {
  CALORIMETRY = 'CALORIMETRY',
  CGM = 'CGM',
}
export enum MedicalTestState {
  FINISHED = 'FINISHED',
  IN_PROGRESS = 'IN_PROGRESS',
  STARTED = 'STARTED',
  CANCELED = 'CANCELED',
}

export interface Transition {
  transition: MedicalTestState;
  transitionDate: string;
}

export enum ActivityLevel {
  Sedentary = 'SEDENTARY',
  Active = 'ACTIVE',
  VeryActive = 'VERY_ACTIVE',
}

export enum CafeConsumption {
  No = 'NO',
  OneOrTwoPerDay = 'ONE_OR_TWO_PER_DAY',
  ThreeOrMorePerDay = 'THREE_OR_MORE_PER_DAY',
}

export enum DietType {
  Keto = 'KETO',
  Mediterranean = 'MEDITERRANEAN',
  Vegan = 'VEGAN',
  LowCarb = 'LOW_CARB',
  MidCarb = 'MID_CARB',
  HighCarb = 'HIGH_CARB',
  Paleo = 'PALEO',
  No = 'NO',
}

export interface MedicalTest {
  actionsToBeTaken: string;
  activityLevel: ActivityLevel;
  alcohol: boolean;
  cafeConsumption: CafeConsumption;
  creationDate: string;
  date: string;
  dietType: DietType;
  deleted: boolean;
  fatPercentage: number;
  finishDate: string;
  foods: Foods[];
  foodIntolerances: Intolerances[];
  gender: Genders;
  height: number;
  id: string;
  medicalTestTypeId: string;
  medicalTestTypeValue: MedicalTestTypeValue;
  medicines: string;
  modificationDate: string;
  name: string;
  nutritionist: Nutritionist;
  objectives: Objectives[];
  observations: string;
  patientId: string;
  serialNumber: string;
  smoke: boolean;
  specialistId: string;
  startDate: string;
  startDateSensor: string;
  state: MedicalTestState;
  transitions: Array<Transition>;
  viewerSpecialistIds: string[];
  weight?: number;
}

export enum PermissionType {
  OWNER = 'OWNER',
  VIEWER = 'VIEWER',
}

export interface MedicalTestTableDto {
  date: string;
  finishDate: string;
  id: string;
  medicalTestPermission: PermissionType;
  medicalTestTypeValue: MedicalTestTypeValue;
  name: string;
  specialist: UserTableDto;
  startDate: string;
  state: MedicalTestState;
  viewerSpecialists: UserTableDto[];
}

export interface MedicalTestCreateFormDto {
  name: string;
  testType: MedicalTestTypeValue;
  specialistId: string;
  viewerSpecialistIds?: string[];
  date?: any;
  hour?: any;
}

export interface Recommendation {
  defaultRecommendation: boolean;
  id: string;
  recommendation: string;
}

export interface BareRecommendation {
  recommendation: string;
  defaultRecommendation: boolean;
}

export interface EventTypeRecommendation {
  assessment: string;
  recommendationList: Recommendation[];
  recommendations: string[];
  score: number;
  type: EventType;
}

export interface OverviewRecommendation {
  efficiency: number;
  explanation: string;
  optimalValue: number;
  optimalValueMax: number;
  optimalValueMin: number;
  recommendation: string;
  scoreType: ScoreType;
  value: number;
}

export interface MedicalTestRecommendation {
  id: string;
  creationDate: string;
  deleted: boolean;
  modificationDate: string;
  patientId: string;
  eventTypeRecommendationList: EventTypeRecommendation[];
  overviewRecommendations: OverviewRecommendation[];
}

export class MedicalTestCreateForm {
  name: string;
  specialistId: string;
  viewerSpecialistIds?: string[];
  date?: string;

  constructor(medicalTestForm: MedicalTestCreateFormDto) {
    this.name = medicalTestForm.name;
    this.specialistId = medicalTestForm.specialistId;
    this.viewerSpecialistIds = medicalTestForm.viewerSpecialistIds;
    if (medicalTestForm.date && medicalTestForm.hour) {
      this.date = getFullDateFromDateAndHour(medicalTestForm.date, medicalTestForm.hour).toISOString();
    }
  }
}

export function getMedicalTestId(medicalTestType: MedicalTestTypeValue) {
  if (medicalTestType == MedicalTestTypeValue.CALORIMETRY) {
    return CALORIMETRY_TEST_ID;
  } else {
    return CGM_TEST_ID;
  }
}

export function getFullDateFromDateAndHour(date: Date, hour: Date) {
  const fullDate = new Date();
  fullDate.setFullYear(date.getFullYear());
  fullDate.setMonth(date.getMonth());
  fullDate.setDate(date.getDate());
  fullDate.setHours(hour.getHours());
  fullDate.setMinutes(hour.getMinutes());
  fullDate.setMilliseconds(hour.getMilliseconds());
  return fullDate;
}
