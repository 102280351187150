
import { EventScoreDto, EventType } from '@/models/Event';
import { Chart, ChartType } from '@/models/Statistics';
import { computed, defineComponent, PropType, Ref, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useCgmDetailStore } from '@/store/cgmDetail.module';
import CgmGlucoseChartVue from '../charts/CgmGlucoseChart.vue';

export default defineComponent({
  components: { CgmGlucoseChartVue },
  emits: ['annotation-event-click'],

  props: {
    dayStatistics: {
      type: Object,
    },
    dayEvents: {
      type: Array as PropType<EventScoreDto[]>,
    },
  },

  setup(props, { emit }) {
    const { t } = useI18n();
    const storeCGM = useCgmDetailStore();
    const glucoseSerie = computed(
      () => props.dayStatistics?.charts?.find((chart: Chart) => chart.type == ChartType.CGM_DAILY)?.series[0],
    );
    const filterOptions = ref([
      { label: t('common.no-filter'), value: undefined },
      { label: t('cgmEvent.TRAINING'), value: EventType.TRAINING },
      { label: t('cgmEvent.MEAL'), value: EventType.MEAL },
      { label: t('cgmEvent.REST'), value: EventType.REST },
      { label: t('cgmEvent.FEELING'), value: EventType.FEELING },
      { label: t('cgmEvent.CUSTOM'), value: EventType.CUSTOM },
    ]);
    const filter: Ref<EventType | undefined> = ref();
    const filteredEvents = computed(() =>
      filter.value ? props.dayEvents?.filter((event: EventScoreDto) => event.type == filter.value) : props.dayEvents,
    );

    const medicalTestDates = computed(() => storeCGM.getmedicalTestSelectedDates);
    const chartStartEndDates = computed(() => {
      return {
        startDateChart: medicalTestDates.value.startDate,
        endDateChart: medicalTestDates.value.endDate,
      };
    });

    const handleAnnotationEventClick = (eventId: string) => {
      emit('annotation-event-click', eventId);
    };

    return { glucoseSerie, filteredEvents, filterOptions, filter, chartStartEndDates, handleAnnotationEventClick };
  },
});
