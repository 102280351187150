import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "days_picker" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "c-filter__day" }
const _hoisted_4 = { class: "c-filter__label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.days, (day, index) => {
      return (_openBlock(), _createElementBlock("div", {
        id: "day_picker",
        key: index,
        class: _normalizeClass(["c-filter", { 'c-filter--active': index == _ctx.daySelected, 'c-filter--disabled': !day.active }]),
        onClick: ($event: any) => (_ctx.changeDay(day, index))
      }, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(day.letter), 1),
        _createElementVNode("span", _hoisted_4, _toDisplayString(day.number), 1)
      ], 10, _hoisted_2))
    }), 128))
  ]))
}