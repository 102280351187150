import { MedicalTest } from '@/models/MedicalTest';
import Statistics from '@/models/Statistics';
import { useProfileStore } from '@/store/profile.module';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import handleError from './util/ErrorHandler';
import { ServiceError } from './util/ServiceError';

const storeProfile = useProfileStore();

class MeasurementService {
  axiosInstance: AxiosInstance;
  constructor() {
    this.axiosInstance = axios.create({
      baseURL: process.env.VUE_APP_API_BASE_URL,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  async findStatistics(patientId: string, medicalTestId: string): Promise<Statistics | undefined> {
    this.axiosInstance.defaults.headers['authorization'] = 'Bearer ' + storeProfile.authToken;
    this.axiosInstance.defaults.headers['Content-Type'] = 'application/json';

    const options: AxiosRequestConfig = {
      params: {
        patientId,
        medicalTestId: medicalTestId,
      },
    };

    try {
      const networkResponse = await this.axiosInstance.get<Statistics>(
        `${process.env.VUE_APP_API_MEASUREMENT_SERVICE}/measurement/calorimetry/statistics?accountId=${storeProfile.accountId}`,
        options,
      );
      const statistics = networkResponse.data;
      console.debug(`>[MeasurementService] Succeed : request findStatistics. Response data: `, statistics);
      return statistics;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
          console.debug(
            `>[MeasurementService] 404: request findStatistics returned '${error.response?.data?.message}' for patient '${patientId}' and medical test '${medicalTestId}''`,
          );
        }
        console.debug(
          `>[MeasurementService] Error: request findStatistics for patient '${patientId}' and medical test '${medicalTestId}''`,
        );
        console.error(error.response);
      } else {
        console.debug(
          `>[MeasurementService] Error: unknown error at findStatistics for patient '${patientId}' and medical test '${medicalTestId}''`,
        );
        console.error(error);
      }
      return undefined;
    }
  }

  async findStatisticsCgm(
    medicalTestId: string,
    patientId: string,
    startDate: string | undefined = undefined,
    endDate: string | undefined = undefined,
  ): Promise<Statistics | ServiceError> {
    this.axiosInstance.defaults.headers['authorization'] = 'Bearer ' + storeProfile.authToken;
    this.axiosInstance.defaults.headers['Content-Type'] = 'application/json';

    const params = [];
    if (startDate) {
      params.push(['startDate', startDate]);
    }
    if (endDate) {
      params.push(['endDate', endDate]);
    }
    params.push(['medicalTestId', medicalTestId]);
    params.push(['patientId', patientId]);
    const searchParams = new URLSearchParams(params);
    try {
      const networkResponse = await this.axiosInstance.get<Statistics>(
        `${process.env.VUE_APP_API_MEASUREMENT_SERVICE}/measurement/cgm/statistics?accountId=${storeProfile.accountId}`,
        { params: searchParams },
      );
      return networkResponse.data;
    } catch (error) {
      return handleError(error);
    }
  }

  async getPdfFile(patientId: string, medicalTestId: string): Promise<any | ServiceError> {
    this.axiosInstance.defaults.headers['authorization'] = 'Bearer ' + storeProfile.authToken;
    this.axiosInstance.defaults.headers['Content-Type'] = 'application/json';

    const options: AxiosRequestConfig = {
      params: {
        patientId,
        medicalTestId: medicalTestId,
      },
    };

    try {
      const networkResponse = await this.axiosInstance.get<Map<string, any>>(
        `${process.env.VUE_APP_API_MEASUREMENT_SERVICE}/measurement/pdf?accountId=${storeProfile.accountId}`,
        options,
      );
      const response = networkResponse.data;
      console.debug(`>[MeasurementService] Succeed : request getPdfFile. Response data: `, response);
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
          console.debug(
            `>[MeasurementService] 404: request getPdfFile returned '${error.response?.data?.message}' for patient '${patientId}' and medical test '${medicalTestId}''`,
          );
        }
        console.debug(
          `>[MeasurementService] Error: request getPdfFile for patient '${patientId}' and medical test '${medicalTestId}''`,
        );
        console.error(error.response);
      } else {
        console.debug(
          `>[MeasurementService] Error: unknown error at getPdfFile for patient '${patientId}' and medical test '${medicalTestId}''`,
        );
        console.error(error);
      }
      return handleError(error);
    }
  }

  async getFinalReportPdfFile(patientId: string, startDate: string, endDate: string): Promise<any | ServiceError> {
    this.axiosInstance.defaults.headers['authorization'] = 'Bearer ' + storeProfile.authToken;
    this.axiosInstance.defaults.headers['Content-Type'] = 'application/json';

    const options: AxiosRequestConfig = {
      params: {
        patientId,
        startDate,
        endDate,
      },
    };

    try {
      const networkResponse = await this.axiosInstance.get<Map<string, any>>(
        `${process.env.VUE_APP_API_MEASUREMENT_SERVICE}/measurement/pdf/cgm/reportPdf?accountId=${storeProfile.accountId}`,
        options,
      );
      const response = networkResponse.data;
      console.debug(`>[MeasurementService] Succeed : request getPdfFile. Response data: `, response);
      return response;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
          console.debug(
            `>[MeasurementService] 404: request getPdfFile report returned '${error.response?.data?.message}' for patient '${patientId}'`,
          );
        }
        console.debug(`>[MeasurementService] Error: request getPdfFile report for patient '${patientId}'`);
        console.error(error.response);
      } else {
        console.debug(`>[MeasurementService] Error: unknown error at getPdfFile report for patient '${patientId}'`);
        console.error(error);
      }
      return handleError(error);
    }
  }

  async createFromCsv(patientId: string, medicalTestId: string, file: any): Promise<MedicalTest | ServiceError> {
    this.axiosInstance.defaults.headers['authorization'] = 'Bearer ' + storeProfile.authToken;
    this.axiosInstance.defaults.headers['Content-Type'] = 'multipart/form-data';

    const options: AxiosRequestConfig = {
      params: {
        patientId,
        medicalTestId: medicalTestId,
      },
    };

    try {
      const networkResponse = await this.axiosInstance.post<MedicalTest>(
        `${process.env.VUE_APP_API_MEASUREMENT_SERVICE}/measurement/csv?accountId=${storeProfile.accountId}`,
        file,
        options,
      );
      const medicalTest = networkResponse.data;
      console.debug(`>[MeasurementService] Succeed : request createFromCsv. Response data: `, medicalTest);
      return medicalTest;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.debug(
          `>[MeasurementService] Error: request createFromCsv for patient '${patientId}' and medical test '${medicalTestId}''`,
        );
        console.error(error.response);

        return new ServiceError(error.response?.data.code);
      } else {
        console.debug(
          `>[MeasurementService] Error: unknown error at createFromCsv for patient '${patientId}' and medical test '${medicalTestId}''`,
        );
        console.error(error);
        return handleError(error);
      }
    }
  }

  async findGlucoseStatistics(
    patientId: string,
    startDate: string,
    endDate: string,
  ): Promise<Statistics | ServiceError> {
    this.axiosInstance.defaults.headers['authorization'] = 'Bearer ' + storeProfile.authToken;
    this.axiosInstance.defaults.headers['Content-Type'] = 'application/json';

    const params = [];

    params.push(['startDate', startDate]);
    params.push(['endDate', endDate]);
    params.push(['patientId', patientId]);

    const searchParams = new URLSearchParams(params);
    try {
      const networkResponse = await this.axiosInstance.get<Statistics>(
        `${process.env.VUE_APP_API_MEASUREMENT_SERVICE}/measurement/statistics/glucose?accountId=${storeProfile.accountId}`,
        { params: searchParams },
      );
      return networkResponse.data;
    } catch (error) {
      return handleError(error);
    }
  }

  async findHeartRateStatistics(
    patientId: string,
    startDate: string,
    endDate: string,
  ): Promise<Statistics | ServiceError> {
    this.axiosInstance.defaults.headers['authorization'] = 'Bearer ' + storeProfile.authToken;
    this.axiosInstance.defaults.headers['Content-Type'] = 'application/json';

    const params = [];

    params.push(['startDate', startDate]);
    params.push(['endDate', endDate]);
    params.push(['patientId', patientId]);

    const searchParams = new URLSearchParams(params);
    try {
      const networkResponse = await this.axiosInstance.get<Statistics>(
        `${process.env.VUE_APP_API_MEASUREMENT_SERVICE}/measurement/statistics/heartrate?accountId=${storeProfile.accountId}`,
        { params: searchParams },
      );
      return networkResponse.data;
    } catch (error) {
      return handleError(error);
    }
  }

  async findStepStatistics(patientId: string, startDate: string, endDate: string): Promise<Statistics | ServiceError> {
    this.axiosInstance.defaults.headers['authorization'] = 'Bearer ' + storeProfile.authToken;
    this.axiosInstance.defaults.headers['Content-Type'] = 'application/json';

    const params = [];

    params.push(['startDate', startDate]);
    params.push(['endDate', endDate]);
    params.push(['patientId', patientId]);

    const searchParams = new URLSearchParams(params);
    try {
      const networkResponse = await this.axiosInstance.get<Statistics>(
        `${process.env.VUE_APP_API_MEASUREMENT_SERVICE}/measurement/statistics/step?accountId=${storeProfile.accountId}`,
        { params: searchParams },
      );
      return networkResponse.data;
    } catch (error) {
      return handleError(error);
    }
  }

  async findDistanceStatistics(
    patientId: string,
    startDate: string,
    endDate: string,
  ): Promise<Statistics | ServiceError> {
    this.axiosInstance.defaults.headers['authorization'] = 'Bearer ' + storeProfile.authToken;
    this.axiosInstance.defaults.headers['Content-Type'] = 'application/json';

    const params = [];

    params.push(['startDate', startDate]);
    params.push(['endDate', endDate]);
    params.push(['patientId', patientId]);

    const searchParams = new URLSearchParams(params);
    try {
      const networkResponse = await this.axiosInstance.get<Statistics>(
        `${process.env.VUE_APP_API_MEASUREMENT_SERVICE}/measurement/statistics/distance?accountId=${storeProfile.accountId}`,
        { params: searchParams },
      );
      return networkResponse.data;
    } catch (error) {
      return handleError(error);
    }
  }

  async findCaloriesStatistics(
    patientId: string,
    startDate: string,
    endDate: string,
  ): Promise<Statistics | ServiceError> {
    this.axiosInstance.defaults.headers['authorization'] = 'Bearer ' + storeProfile.authToken;
    this.axiosInstance.defaults.headers['Content-Type'] = 'application/json';

    const params = [];

    params.push(['startDate', startDate]);
    params.push(['endDate', endDate]);
    params.push(['patientId', patientId]);

    const searchParams = new URLSearchParams(params);
    try {
      const networkResponse = await this.axiosInstance.get<Statistics>(
        `${process.env.VUE_APP_API_MEASUREMENT_SERVICE}/measurement/statistics/calories?accountId=${storeProfile.accountId}`,
        { params: searchParams },
      );
      return networkResponse.data;
    } catch (error) {
      return handleError(error);
    }
  }
}
export const measurementService = new MeasurementService();
