import { EventType } from '@/models/Event';
const iconHelper = {
  eventTypeIcon(type: EventType, end = false) {
    switch (type) {
      case EventType.CUSTOM:
        return 'custom.svg';
      case EventType.FEELING:
        return 'feeling.svg';
      case EventType.MEAL:
        return 'meal.svg';
      case EventType.REST:
        return end ? 'rest-end.svg' : 'rest.svg';
      case EventType.TRAINING:
        return end ? 'training-end.svg' : 'training.svg';
    }
  },
};
export default iconHelper;
