import moment from 'moment';
import { useI18n } from 'vue-i18n';
import dateFormat from '@/helpers/DateFormat.helper';

interface DayPick {
  number: string;
  letter: string;
  dateStart: string;
  dateEnd: string;
  active: boolean;
}

function calculateListDays(firstDayPicker: string, limitDay: string, numDays: number) {
  const { t } = useI18n();
  const dates: DayPick[] = [];
  const today = moment(new Date());
  const startDate = moment(firstDayPicker);
  const endDate = moment(firstDayPicker).add(numDays - 1, 'days');
  const limitDate = moment(limitDay);
  while (startDate.isSameOrBefore(endDate)) {
    const newDay: DayPick = {
      dateStart: dateFormat.startOfCertainDay(startDate.format('YYYY-MM-DDTHH:mm:ss')),
      dateEnd: dateFormat.endOfCertainDay(startDate.format('YYYY-MM-DDTHH:mm:ss')),
      number: startDate.format('D MMM'),
      letter: startDate.format('dd'),
      active: true,
    };

    if (startDate.format('YYYY-MM-DD') == today.format('YYYY-MM-DD')) {
      //Is today
      if (startDate.isAfter(limitDate)) {
        newDay.active = false;
      } else {
        newDay.number = t('common.today');
      }
    } else if (startDate.isAfter(today) || startDate.isAfter(limitDate)) {
      //Next days
      newDay.active = false;
    }
    dates.push(newDay);
    startDate.add(1, 'days');
  }

  return dates;
}

export { calculateListDays, DayPick };
