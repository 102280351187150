import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between" }
const _hoisted_2 = { class: "p-input-icon-left p-col-4" }
const _hoisted_3 = { class: "events-table__name-wrapper" }
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Column = _resolveComponent("Column")!
  const _component_ScoreCircular = _resolveComponent("ScoreCircular")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_ctx.events)
    ? (_openBlock(), _createBlock(_component_DataTable, {
        key: 0,
        ref: "dt",
        filters: _ctx.filters,
        "onUpdate:filters": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filters) = $event)),
        class: "events-data-table",
        value: _ctx.dataTableEvents,
        "data-key": "id",
        rows: 10,
        "filter-display": "menu",
        loading: _ctx.loading,
        "paginator-template": "FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink",
        paginator: _ctx.showPaginator,
        "responsive-layout": "scroll",
        "global-filter-fields": ['name'],
        "sort-field": "date",
        "sort-order": 1,
        "removable-sort": "",
        rowHover: true,
        onRowClick: _cache[3] || (_cache[3] = 
      (event) => {
        _ctx.visitDetail(event?.data?.id);
      }
    )
      }, _createSlots({
        empty: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('events.notFound')), 1)
        ]),
        loading: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('events.loading')), 1)
        ]),
        default: _withCtx(() => [
          (_ctx.fields.includes('name'))
            ? (_openBlock(), _createBlock(_component_Column, {
                key: 0,
                showFilterMatchModes: false,
                field: "name",
                header: _ctx.$t('events.name')
              }, {
                body: _withCtx((slotProps) => [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("img", {
                      src: `${_ctx.iconsRootPath}events/${_ctx.iconHelper.eventTypeIcon(slotProps.data.type)}`,
                      alt: "icono"
                    }, null, 8, _hoisted_4),
                    _createTextVNode(" " + _toDisplayString(_ctx.eventName(slotProps.data)), 1)
                  ])
                ]),
                filterclear: _withCtx(() => _cache[6] || (_cache[6] = [])),
                filterapply: _withCtx(() => _cache[7] || (_cache[7] = [])),
                _: 1
              }, 8, ["header"]))
            : _createCommentVNode("", true),
          (_ctx.fields.includes('score'))
            ? (_openBlock(), _createBlock(_component_Column, {
                key: 1,
                field: "scoreValue",
                header: _ctx.$t('events.score')
              }, {
                body: _withCtx((slotProps) => [
                  _createVNode(_component_ScoreCircular, {
                    class: "c-card__circular-score",
                    score: slotProps.data.scoreValue,
                    color: _ctx.scoreHelper.scoreColor(slotProps.data.scoreValue)
                  }, null, 8, ["score", "color"])
                ]),
                filterclear: _withCtx(() => _cache[8] || (_cache[8] = [])),
                filterapply: _withCtx(() => _cache[9] || (_cache[9] = [])),
                _: 1
              }, 8, ["header"]))
            : _createCommentVNode("", true),
          (_ctx.fields.includes('date'))
            ? (_openBlock(), _createBlock(_component_Column, {
                key: 2,
                field: "date",
                header: _ctx.$t('events.date'),
                "data-type": "date",
                showFilterMatchModes: false,
                showApplyButton: false,
                "filter-field": "date",
                sortable: ""
              }, {
                body: _withCtx((slotProps) => [
                  _createTextVNode(_toDisplayString(_ctx.dateFormat.formatDateAndHour(_ctx.dateFormat.utcToTimezone(slotProps.data.date, _ctx.patientTimezone))), 1)
                ]),
                filter: _withCtx(({ filterModel, filterCallback }) => [
                  _createVNode(_component_Calendar, {
                    modelValue: filterModel.value,
                    "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                    "date-format": _ctx.dateFormat.dateFormat(),
                    placeholder: _ctx.dateFormat.dateFormat(),
                    onDateSelect: ($event: any) => (filterCallback())
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "date-format", "placeholder", "onDateSelect"])
                ]),
                filterclear: _withCtx(() => _cache[10] || (_cache[10] = [])),
                _: 1
              }, 8, ["header"]))
            : _createCommentVNode("", true),
          (_ctx.fields.includes('type'))
            ? (_openBlock(), _createBlock(_component_Column, {
                key: 3,
                field: "type",
                header: _ctx.$t('events.type'),
                showFilterMatchModes: false
              }, {
                body: _withCtx((slotProps) => [
                  _createTextVNode(_toDisplayString(_ctx.$t(`events.${slotProps.data.type}`)), 1)
                ]),
                filter: _withCtx(({ filterModel, filterCallback }) => [
                  _createVNode(_component_Dropdown, {
                    modelValue: filterModel.value,
                    "onUpdate:modelValue": ($event: any) => ((filterModel.value) = $event),
                    options: _ctx.eventTypeFilterOptions,
                    class: "events__select-type-filter",
                    optionLabel: "name",
                    optionValue: "value",
                    placeholder: _ctx.$t('events.filter-by-type'),
                    onChange: ($event: any) => (filterCallback())
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "placeholder", "onChange"])
                ]),
                filterclear: _withCtx(() => _cache[11] || (_cache[11] = [])),
                filterapply: _withCtx(() => _cache[12] || (_cache[12] = [])),
                _: 1
              }, 8, ["header"]))
            : _createCommentVNode("", true),
          (_ctx.fields.includes('actions'))
            ? (_openBlock(), _createBlock(_component_Column, {
                key: 4,
                header: _ctx.$t('table.actions')
              }, {
                body: _withCtx((slotProps) => [
                  _createVNode(_component_Button, {
                    class: "p-button-table",
                    onClick: 
            () => {
              _ctx.visitDetail(slotProps?.data?.id);
            }
          
                  }, {
                    default: _withCtx(() => [
                      _cache[13] || (_cache[13] = _createElementVNode("i", {
                        class: "icon-show",
                        "aria-hidden": "true"
                      }, null, -1)),
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('events.see-event')), 1)
                    ]),
                    _: 2
                  }, 1032, ["onClick"])
                ]),
                _: 1
              }, 8, ["header"]))
            : _createCommentVNode("", true)
        ]),
        _: 2
      }, [
        (_ctx.displayGlobalFilters)
          ? {
              name: "header",
              fn: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("span", _hoisted_2, [
                    _cache[4] || (_cache[4] = _createElementVNode("i", {
                      class: "icon-search",
                      "aria-hidden": "true"
                    }, null, -1)),
                    _createVNode(_component_InputText, {
                      modelValue: _ctx.filters['global'].value,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filters['global'].value) = $event)),
                      placeholder: _ctx.$t('table.search.events')
                    }, null, 8, ["modelValue", "placeholder"])
                  ]),
                  _createVNode(_component_Button, {
                    type: "button",
                    class: "p-button p-component p-button-secondary p-button-medium p-button-icon",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.initFilters()))
                  }, {
                    default: _withCtx(() => [
                      _cache[5] || (_cache[5] = _createElementVNode("i", {
                        class: "icon-trash_empty",
                        "aria-hidden": "true"
                      }, null, -1)),
                      _createElementVNode("span", null, _toDisplayString(_ctx.$t('table.clearFilters')), 1)
                    ]),
                    _: 1
                  })
                ])
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["filters", "value", "loading", "paginator"]))
    : _createCommentVNode("", true)
}