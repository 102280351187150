import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "l-row--valign"
}
const _hoisted_2 = {
  key: 0,
  class: "cgm-follow-up__daily-empty-state"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingComponent = _resolveComponent("LoadingComponent")!
  const _component_EmptyState = _resolveComponent("EmptyState")!
  const _component_GetRequestError = _resolveComponent("GetRequestError")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DaysListPicker = _resolveComponent("DaysListPicker")!
  const _component_CgmFollowUpGeneral = _resolveComponent("CgmFollowUpGeneral")!
  const _component_CgmFollowUpDay = _resolveComponent("CgmFollowUpDay")!

  return (_ctx.isLoadingPage)
    ? (_openBlock(), _createBlock(_component_LoadingComponent, {
        key: 0,
        spinnerColor: "lavender-700"
      }))
    : (_ctx.isMedicalTestCanceledAndEmpty)
      ? (_openBlock(), _createBlock(_component_EmptyState, {
          key: 1,
          heading: _ctx.$t('medicalTest.no-data-title'),
          text: _ctx.$t('medicalTest.canceled-test-no-data-message'),
          icon: "icon-window_close"
        }, null, 8, ["heading", "text"]))
      : (_ctx.isMedicalTestStateStarted)
        ? (_openBlock(), _createBlock(_component_EmptyState, {
            key: 2,
            heading: _ctx.$t('cgmDetail.not-started-title'),
            icon: "icon-loading",
            text: _ctx.$t('cgmDetail.not-started-message'),
            textAtBottom: true
          }, null, 8, ["heading", "text"]))
        : (_ctx.isLoadingWeeklyDataErrorful)
          ? (_openBlock(), _createBlock(_component_GetRequestError, { key: 3 }))
          : (!_ctx.statisticsWeek1)
            ? (_openBlock(), _createBlock(_component_EmptyState, {
                key: 4,
                heading: _ctx.$t('cgmDetail.no-data-title'),
                icon: "icon-alarm",
                text: _ctx.$t('cgmDetail.no-data-message'),
                textAtBottom: true
              }, null, 8, ["heading", "text"]))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 5 }, [
                (_ctx.viewOptions && _ctx.viewOptions.length > 0)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      _createVNode(_component_Dropdown, {
                        id: "selectView",
                        modelValue: _ctx.selectedView,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedView) = $event)),
                        options: _ctx.viewOptions,
                        optionLabel: "label",
                        optionValue: "value",
                        onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onChangeSelectedView($event.value)))
                      }, null, 8, ["modelValue", "options"]),
                      (_ctx.selectedView === _ctx.ViewOption.OVERVIEW)
                        ? (_openBlock(), _createBlock(_component_Button, {
                            key: 0,
                            label: _ctx.$t('common.general')
                          }, null, 8, ["label"]))
                        : (_openBlock(), _createBlock(_component_DaysListPicker, {
                            key: 1,
                            firstDayPicker: _ctx.initialDatePatientTimeZone,
                            numDays: _ctx.numDays,
                            defaultDay: _ctx.selectedStartDate,
                            limitDay: _ctx.cancellationDay,
                            onOnChange: _ctx.onDayChanged
                          }, null, 8, ["firstDayPicker", "numDays", "defaultDay", "limitDay", "onOnChange"]))
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.selectedView === _ctx.ViewOption.OVERVIEW)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                      (_ctx.isLoadingDailyData)
                        ? (_openBlock(), _createBlock(_component_LoadingComponent, {
                            key: 0,
                            spinnerColor: "lavender-700"
                          }))
                        : (_ctx.selectedOvewviewButton === _ctx.OverviewOption.GENERAL)
                          ? (_openBlock(), _createBlock(_component_CgmFollowUpGeneral, {
                              key: 1,
                              week1Statistics: _ctx.statisticsWeek1,
                              week2Statistics: _ctx.statisticsWeek2,
                              onChangeViewToSpecificDay: _ctx.changeViewToSpecificDay
                            }, null, 8, ["week1Statistics", "week2Statistics", "onChangeViewToSpecificDay"]))
                          : _createCommentVNode("", true)
                    ], 64))
                  : _createCommentVNode("", true),
                (_ctx.selectedView === _ctx.ViewOption.DAILY)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                      (_ctx.isEmptyDataForTheDay)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                            (_ctx.isMedicalTestCanceled)
                              ? (_openBlock(), _createBlock(_component_EmptyState, {
                                  key: 0,
                                  heading: _ctx.$t('medicalTest.canceled-test-final-analysis-heading'),
                                  icon: "icon-confused",
                                  text: _ctx.$t('medicalTest.canceled-test-no-data-message'),
                                  textAtBottom: true
                                }, null, 8, ["heading", "text"]))
                              : (_openBlock(), _createBlock(_component_EmptyState, {
                                  key: 1,
                                  heading: _ctx.$t('cgmDetail.no-data-title'),
                                  icon: "icon-confused",
                                  text: _ctx.$t('cgmDetail.no-data-message'),
                                  textAtBottom: true
                                }, null, 8, ["heading", "text"]))
                          ]))
                        : (_openBlock(), _createBlock(_component_CgmFollowUpDay, {
                            key: 1,
                            loading: _ctx.isLoadingDailyData,
                            dayStatistics: _ctx.statisticsSelectedDay,
                            dayEvents: _ctx.eventsSelectedDay,
                            isErrorful: _ctx.isLoadingDailyDataErrorful
                          }, null, 8, ["loading", "dayStatistics", "dayEvents", "isErrorful"]))
                    ], 64))
                  : _createCommentVNode("", true)
              ], 64))
}