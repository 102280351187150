import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "grid-container" }
const _hoisted_2 = { class: "grid-item-score" }
const _hoisted_3 = { class: "c_grid-item-score__text" }
const _hoisted_4 = { class: "c_grid-item-score__score" }
const _hoisted_5 = { class: "grid-item-chart" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CgmAverageScoreChart = _resolveComponent("CgmAverageScoreChart")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("p", null, _toDisplayString(_ctx.$t('cgmDetail.overview-score-avg')), 1),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('cgm-scores.patient-score')), 1),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.averageScoreMedia), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_ctx.combinedSerie)
          ? (_openBlock(), _createBlock(_component_CgmAverageScoreChart, {
              key: 0,
              changeViewToSpecificDay: _ctx.changeViewToSpecificDay,
              serie: _ctx.combinedSerie
            }, null, 8, ["changeViewToSpecificDay", "serie"]))
          : _createCommentVNode("", true)
      ])
    ])
  ], 64))
}