import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "p-grid--cgm-general" }
const _hoisted_2 = { class: "card card--no-pading" }
const _hoisted_3 = { class: "card" }
const _hoisted_4 = { class: "card" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CgmAverageScoreCard = _resolveComponent("CgmAverageScoreCard")!
  const _component_CgmAccordionScoreList = _resolveComponent("CgmAccordionScoreList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CgmAverageScoreCard, {
        changeViewToSpecificDay: _ctx.changeViewToSpecificDay,
        week1Statistics: _ctx.week1Statistics,
        week2Statistics: _ctx.week2Statistics
      }, null, 8, ["changeViewToSpecificDay", "week1Statistics", "week2Statistics"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_CgmAccordionScoreList, {
        title: _ctx.$t('cgmDetail.overview-week1'),
        statistics: _ctx.week1Statistics
      }, null, 8, ["title", "statistics"])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_CgmAccordionScoreList, {
        title: _ctx.$t('cgmDetail.overview-week2'),
        statistics: _ctx.week2Statistics
      }, null, 8, ["title", "statistics"])
    ])
  ]))
}