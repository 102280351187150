
import Statistics, { ChartType, Serie } from '@/models/Statistics';
import { computed, defineComponent, PropType } from 'vue';
import CgmAverageScoreChart from '../charts/CgmAverageScoreChart.vue';

export default defineComponent({
  components: { CgmAverageScoreChart },
  props: {
    week1Statistics: {
      type: Object as PropType<Statistics | undefined>,
    },
    week2Statistics: {
      type: Object as PropType<Statistics | undefined>,
    },
    changeViewToSpecificDay: {
      type: Function,
    },
  },
  setup(props) {
    const combinedSerie = computed(() => {
      const week1Serie = props.week1Statistics?.charts?.find((chart) => chart.type == ChartType.CGM_SCORE_PER_DAY)
        ?.series[0];
      if (!week1Serie) {
        return undefined;
      }
      const week2Serie = props.week2Statistics?.charts?.find((chart) => chart.type == ChartType.CGM_SCORE_PER_DAY)
        ?.series[0];
      if (!week2Serie) {
        return week1Serie;
      }
      let innerCombinedSerie = { name: week1Serie.name, data: [], xaxis: [] } as Serie;
      innerCombinedSerie.data.push(...week1Serie.data);
      innerCombinedSerie.xaxis.push(...week1Serie.xaxis);
      innerCombinedSerie.data.push(...week2Serie.data);
      innerCombinedSerie.xaxis.push(...week2Serie.xaxis);
      return innerCombinedSerie;
    });

    const averageScoreMedia = computed(() => {
      if (!combinedSerie.value || combinedSerie.value.data.length == 0) {
        return 0;
      }
      const sum = combinedSerie.value.data.reduce((a, b) => a + b, 0);
      const resultValue = sum / combinedSerie.value.data.filter((item) => item != null).length;
      if (resultValue !== resultValue) {
        return '--';
      }
      return resultValue.toFixed(2);
    });

    return { combinedSerie, averageScoreMedia };
  },
});
