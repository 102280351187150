
import { defineComponent, PropType, computed } from 'vue';
import Statistics from '@/models/Statistics';
import CgmAccordionScoreList from './CgmAccordionScoreList.vue';
import CgmDailyGlucoseCard from './CgmDailyGlucoseCard.vue';
import { EventScoreDto } from '@/models/Event';
import { useRoute, useRouter } from 'vue-router';
import EventsTable from '@/components/cgm/EventsTable.vue';
import dateFormat from '@/helpers/DateFormat.helper';
import EmptyState from '@/components/EmptyState.vue';
import HeadingComponent from '@/components/HeadingComponent.vue';
import LoadingComponent from '@/components/LoadingComponent.vue';
import GetRequestError from '@/components/common/GetRequestError.vue';
export default defineComponent({
  components: {
    CgmAccordionScoreList,
    EventsTable,
    CgmDailyGlucoseCard,
    EmptyState,
    HeadingComponent,
    LoadingComponent,
    GetRequestError,
  },
  props: {
    dayStatistics: {
      type: Object as PropType<Statistics | undefined>,
    },
    dayEvents: {
      type: Array as PropType<EventScoreDto[]>,
      required: true,
    },
    loading: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    isErrorful: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const medicalTestId = route.params.medicalTest as string;
    const patientId = route.params.patientId as string;

    const handleAnnotationEventClick = async (eventId: string) => {
      await router.push(`/patient/${patientId}/medical-test/cgm/${medicalTestId}/event/${eventId}`);
    };

    const badEvents = computed(() => {
      if (props.dayEvents && props.dayEvents.length > 0) {
        return props.dayEvents
          .filter((event) => event.score && event.score.value && event.score.value <= 6)
          .sort((a, b) => dateFormat.getTimeFromDateString(a.date) - dateFormat.getTimeFromDateString(b.date));
      } else {
        return [];
      }
    });

    return { handleAnnotationEventClick, badEvents };
  },
});
