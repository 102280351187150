
import Statistics from '@/models/Statistics';
import { defineComponent, PropType } from 'vue';
import CgmAccordionScoreList from './CgmAccordionScoreList.vue';
import CgmAverageScoreCard from './CgmAverageScoreCard.vue';

export default defineComponent({
  components: { CgmAccordionScoreList, CgmAverageScoreCard },
  emits: ['changeViewToSpecificDay'],
  props: {
    week1Statistics: {
      type: Object as PropType<Statistics | undefined>,
    },
    week2Statistics: {
      type: Object as PropType<Statistics | undefined>,
    },
    changeToSpecificDay: {
      type: Function,
    },
  },
  setup(props, { emit }) {
    function changeViewToSpecificDay(selectedUtcDay: string) {
      emit('changeViewToSpecificDay', selectedUtcDay);
    }
    return { changeViewToSpecificDay };
  },
});
