
import { ref, Ref } from 'vue';
import { defineComponent } from '@vue/runtime-core';
import moment from 'moment';
import i18n from '@/i18n';
import { calculateListDays, DayPick } from '@/helpers/DaysPicker.helper';

export default defineComponent({
  emits: ['onChange'],
  props: {
    firstDayPicker: {
      type: String,
      required: true,
    },
    numDays: {
      type: Number,
      required: true,
    },
    defaultDay: {
      type: String,
      required: true,
    },
    limitDay: {
      type: String,
      default: () => '',
    },
  },
  setup(props, context) {
    const daySelected = ref(0);
    const days: Ref<DayPick[]> = ref(fetchDays());

    function fetchDays() {
      moment.locale(i18n.global.locale.value);
      const dates = calculateListDays(props.firstDayPicker, props.limitDay, props.numDays);

      daySelected.value = dates.findIndex(
        (date: DayPick) => moment(date.dateStart).format('YYYY-MM-DD') == moment(props.defaultDay).format('YYYY-MM-DD'),
      );

      if (daySelected.value != undefined && daySelected.value >= 0) {
        context.emit('onChange', dates[daySelected.value]?.dateStart, dates[daySelected.value]?.dateEnd);
      }

      return dates;
    }

    const changeDay = async (date: DayPick, index: number) => {
      daySelected.value = index;
      context.emit('onChange', date.dateStart, date.dateEnd);
    };

    return { days, changeDay, daySelected };
  },
});
