
import { defineComponent, ref, computed } from 'vue';
import dateFormat from '@/helpers/DateFormat.helper';
import iconHelper from '@/helpers/IconHelper';
import { FilterMatchMode } from 'primevue/api';
import ScoreCircular from '../charts/ScoreCircular.vue';
import scoreHelper from '@/helpers/Score.helper';
import { Event, EventType } from '@/models/Event';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useCgmDetailStore } from '@/store/cgmDetail.module';
export default defineComponent({
  components: {
    ScoreCircular,
  },
  props: {
    events: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      default: () => ['name', 'score', 'date', 'type', 'actions'],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hasGlobalFilters: {
      type: Boolean,
      default: false,
    },
    displayGlobalFilters: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const router = useRouter();
    const storeCGM = useCgmDetailStore();
    const iconsRootPath = `${window.location.origin}/assets/icon/`;
    const filters: any = ref([]);
    const showPaginator = computed(() => props.events && props.events.length > 10);
    const patientTimezone = computed(() => storeCGM.getMedicalTestSelectedUserTimezone);
    const visitDetail = async (eventId: string) => {
      if (eventId) {
        await router.push({
          name: 'event-detail',
          params: {
            eventId,
          },
        });
      }
    };

    const eventTypeFilterOptions = computed(() => {
      return [undefined, ...Object.values(EventType)].map((type) => ({
        name: type ? t(`events.${type}`) : t('common.all'),
        value: type,
      }));
    });
    const dataTableEvents = computed(() => {
      return props.events.map((event: any) => {
        // filters dont work correctly with a nested object, so we need to offer the score value as a separate field
        return { scoreValue: event.score?.value, ...event };
      });
    });

    const eventName = (event: Event) => {
      if (event.name) {
        return event.name;
      }
      if (event.subType) {
        return t(`events.subtype.${event.subType}`);
      }
      return t(`events.${event.type}`);
    };

    const initFilters = () => {
      filters.value = {
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        date: { value: null, matchMode: 'filterEqualDate' },
        scoreValue: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        type: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      };
    };
    initFilters();

    return {
      filters,
      showPaginator,
      dateFormat,
      iconHelper,
      iconsRootPath,
      scoreHelper,
      initFilters,
      dataTableEvents,
      eventTypeFilterOptions,
      visitDetail,
      eventName,
      patientTimezone,
    };
  },
});
