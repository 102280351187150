
import { Serie } from '@/models/Statistics';
import { computed, defineComponent, onMounted, onUnmounted, PropType, watch } from 'vue';
import chartDataLabels from 'chartjs-plugin-datalabels';
import moment from 'moment';
import Chart from 'chart.js/auto';
import dateFormat from '@/helpers/DateFormat.helper';
import { useCgmDetailStore } from '@/store/cgmDetail.module';

export default defineComponent({
  components: {},
  props: {
    serie: {
      type: Object as PropType<Serie | undefined>,
    },
    changeViewToSpecificDay: {
      type: Function,
    },
  },
  setup(props) {
    Chart.register(chartDataLabels);
    const storeCGM = useCgmDetailStore();
    const patientTimezone = computed(() => storeCGM.getMedicalTestSelectedUserTimezone);
    const serie = computed(() => props.serie);
    let chart: any = null;

    watch(serie, () => redrawChart());

    onMounted(() => {
      redrawChart();
    });
    onUnmounted(() => {
      Chart.unregister(chartDataLabels);
    });

    function redrawChart() {
      if (!serie.value) {
        return;
      }
      const container = document.getElementById('glucose-cgm-weekly-chart');
      if (!container) {
        return;
      }
      const canvas = container as HTMLCanvasElement;
      if (!canvas) {
        return;
      }

      if (chart) {
        (chart as Chart).data = getChartData();
        (chart as Chart).options = getChartOptions();
        chart.update();
      } else {
        const chartConfig: any = {
          type: 'line',
          data: getChartData(),
          options: getChartOptions(),
        };

        chart = new Chart(canvas, chartConfig);
      }
    }

    function getAxisFormatted(axis: string[]) {
      return axis.map((date: any) => {
        return moment(dateFormat.utcToTimezone(date, patientTimezone.value)).format('dd');
      });
    }

    function getChartData(): any {
      if (!serie.value) {
        return {};
      }
      return {
        labels: getAxisFormatted(serie.value.xaxis),
        datasets: [
          {
            data: serie.value.data,
            backgroundColor: 'transparent',
            pointBackgroundColor: '#BA97EA',
            pointBorderColor: '#DFCAFB',
            borderColor: '#8459C0',
            showLine: true,
            tension: 0.4,
            spanGaps: true,
          },
        ],
      };
    }

    function getChartOptions(): any {
      return {
        animation: {
          duration: 0,
        },
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 20,
            bottom: 0,
          },
        },
        elements: {
          point: {
            radius: 6,
            borderWidth: 2,
          },
          line: {
            borderWidth: 3,
          },
        },
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          title: {
            display: false,
          },
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
          datalabels: {
            display: true,
            align: 'top',
            backgroundColor: 'transparent',
            color: '#8459C0',
            borderRadius: 0,
            font: {
              size: 12,
              family: 'Rubik',
            },
            formatter: function (value: any) {
              return value.toFixed(1);
            },
          },
        },
        interaction: {
          intersect: false,
        },
        scales: {
          x: {
            display: true,
            title: {
              display: true,
            },
            grid: {
              display: false,
              drawOnChartArea: false,
              drawBorder: false,
              drawTicks: false,
            },
            ticks: {
              color: '#8459C0',
              padding: 12,
              font: {
                size: 12,
                family: 'Rubik',
              },
            },
          },
          y: {
            display: false,
            suggestedMin: 0,
            suggestedMax: 10,
            grid: {
              display: false,
              drawOnChartArea: true,
              drawBorder: false,
              drawTicks: false,
            },
            ticks: {
              color: '#545454',
              padding: 8,
              font: {
                size: 12,
                family: 'Rubik',
              },
            },
          },
        },
        onHover: (event: any, activeElements: any) => {
          event.native.target.style.cursor = activeElements[0] ? 'pointer' : 'default';
        },
        onClick: (event: any, activeElements: any) => {
          if (activeElements.length === 0) {
            return;
          }
          const elementIndex = activeElements[0].index;
          if (props.changeViewToSpecificDay) {
            props.changeViewToSpecificDay(props.serie?.xaxis[elementIndex]);
          }
        },
      };
    }
  },
});
