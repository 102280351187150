
import i18n from '@/i18n';
import { defineComponent, ref, Ref, computed, watchEffect, onMounted } from 'vue';
import CgmFollowUpGeneral from './CgmFollowUpGeneral.vue';
import CgmFollowUpDay from './CgmFollowUpDay.vue';
import { useRoute } from 'vue-router';
import DaysListPicker from '../common/DaysListPicker.vue';
import EmptyState from '../EmptyState.vue';
import { useCgmDetailStore } from '@/store/cgmDetail.module';
import { MedicalTest, MedicalTestState } from '@/models/MedicalTest';
import { eventService } from '@/services/EventService';
import dateFormat from '@/helpers/DateFormat.helper';
import moment from 'moment';
import { measurementService } from '@/services/MeasurementService';
import { useI18n } from 'vue-i18n';
import { ServiceError } from '@/services/util/ServiceError';
import LoadingComponent from '../LoadingComponent.vue';
import router from '@/router';
import GetRequestError from '@/components/common/GetRequestError.vue';
import { Chart, ChartType } from '@/models/Statistics';

enum ViewOption {
  OVERVIEW = 'OVERVIEW',
  DAILY = 'DAILY',
}
enum OverviewOption {
  GENERAL = 'GENERAL',
}

interface WeekPick {
  dateStart: string;
  dateEnd: string;
}

export default defineComponent({
  components: { CgmFollowUpGeneral, CgmFollowUpDay, DaysListPicker, EmptyState, LoadingComponent, GetRequestError },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const storeCGM = useCgmDetailStore();
    const patientId = route.params.patientId as string;
    const medicalTestId = route.params.medicalTest as string;
    const medicalTest = computed(() => storeCGM.getMedicalTestSelected as MedicalTest);
    const patientTimeZone = computed(() => storeCGM.getMedicalTestSelectedUserTimezone);
    const isMedicalTestStateStarted = computed(
      () => !medicalTest.value || medicalTest.value?.state === MedicalTestState.STARTED,
    );

    const isMedicalTestCanceled = computed(() => medicalTest.value?.state === MedicalTestState.CANCELED);

    const isMedicalTestCanceledAndEmpty = computed(() => {
      return isMedicalTestCanceled.value && !statisticsWeek1.value && !statisticsWeek2.value;
    });

    const statisticsWeek1 = ref();
    const statisticsWeek2 = ref();
    const statisticsSelectedDay = ref();
    const eventsSelectedDay = ref<any[]>([]);
    const isLoadingPage = ref(true);
    const isLoadingDailyData = ref(false);
    const cancellationDay = ref('');
    const selectedView = ref(
      storeCGM.getmedicalTestSelectedView ? (storeCGM.getmedicalTestSelectedView as ViewOption) : ViewOption.OVERVIEW,
    );
    const selectedOvewviewButton = ref(OverviewOption.GENERAL);
    const viewOptions = ref([
      { label: t(`cgmDetail.${ViewOption.OVERVIEW}`), value: ViewOption.OVERVIEW },
      { label: t(`cgmDetail.${ViewOption.DAILY}`), value: ViewOption.DAILY },
    ]);

    onMounted(() => {
      const cancelTransition = medicalTest.value.transitions.find(
        (transition) => transition.transition === MedicalTestState.CANCELED,
      );
      if (cancelTransition) {
        cancellationDay.value = cancelTransition.transitionDate;
      }
    });

    const numDays = ref(14);
    const numWeeks = ref(2);
    const MEDICAL_TEST_TIME_SPAN = 13;

    const selectedStartDate = computed(() => storeCGM.getMedicalTestSelectedStartDate);
    const isLoadingWeeklyDataErrorful = ref(false);
    const isLoadingDailyDataErrorful = ref(false);
    const initialDatePatientTimeZone = computed(() => {
      return medicalTest.value?.startDate && patientTimeZone.value
        ? dateFormat.utcToTimezone(medicalTest.value?.startDate, patientTimeZone.value)
        : '';
    });

    const cgmWeeksDates: Ref<WeekPick[] | undefined> = ref();

    const glucoseSerie = computed(
      () =>
        statisticsSelectedDay.value?.charts?.find((chart: Chart) => chart.type == ChartType.CGM_DAILY)?.series[0]?.data,
    );

    const isEmptyDataForTheDay = computed(() => {
      //return glucoseSerie.value having no length or with only empty values
      return (
        !glucoseSerie.value ||
        glucoseSerie.value.length == 0 ||
        glucoseSerie.value.every((value: any) => value == 0 || value == null || value == undefined)
      );
    });

    function calculateWeeksDates() {
      moment.locale(i18n.global.locale.value);
      const weeks = [];
      const startDate = moment(initialDatePatientTimeZone.value);
      const endDate = moment(initialDatePatientTimeZone.value).add(numWeeks.value * 7 - 1, 'days');

      while (startDate.isSameOrBefore(endDate)) {
        const newWeek: WeekPick = {
          dateStart: dateFormat.startOfCertainDay(startDate.format('YYYY-MM-DDTHH:mm:ss')),
          dateEnd: dateFormat.endOfCertainDay(startDate.add(6, 'days').format('YYYY-MM-DDTHH:mm:ss')),
        };
        weeks.push(newWeek);
        startDate.add(1, 'days');
      }
      cgmWeeksDates.value = weeks;
    }

    async function onChangeSelectedView(actualView: ViewOption) {
      if (actualView) {
        storeCGM.setMedicalTestSelectedView(actualView);
        if (actualView == ViewOption.DAILY) {
          await setupSelectedDates();
        }
      }
    }

    const onDayChanged = async (startDate: string, endDate: string) => {
      isLoadingDailyData.value = true;
      storeCGM.setMedicalTestSelectedDates({
        startDate: startDate,
        endDate: endDate,
      });

      const statisticsResponse = await getStatistics(startDate, endDate);
      statisticsSelectedDay.value = statisticsResponse;
      const eventsResponse = await eventService.findByMedicalTestAndPatientId(
        medicalTestId,
        patientId,
        true,
        dateFormat.timezoneToUtc(startDate, patientTimeZone.value),
        dateFormat.timezoneToUtc(endDate, patientTimeZone.value),
      );

      if (eventsResponse instanceof ServiceError || statisticsResponse instanceof ServiceError) {
        isLoadingDailyDataErrorful.value = true;
        isLoadingDailyData.value = false;
        eventsSelectedDay.value = [];
      } else {
        eventsSelectedDay.value = eventsResponse || [];
      }

      isLoadingDailyData.value = false;
    };

    async function setupSelectedDates() {
      if (!selectedStartDate.value) {
        await setDefaultSelectedStartDate();
      }
    }

    async function setDefaultSelectedStartDate() {
      if (initialDatePatientTimeZone.value) {
        const lastDay = moment(initialDatePatientTimeZone.value)
          .add(MEDICAL_TEST_TIME_SPAN, 'days')
          .startOf('day')
          .format('YYYY-MM-DDTHH:mm:ss');

        const startDate = moment(initialDatePatientTimeZone.value)
          .add(MEDICAL_TEST_TIME_SPAN, 'days')
          .endOf('day')
          .isSameOrBefore(moment())
          ? lastDay
          : moment().format('YYYY-MM-DDTHH:mm:ss');

        storeCGM.setMedicalTestSelectedDates({
          startDate: startDate,
          endDate: '',
        });
      }
    }

    watchEffect(() => {
      if (initialDatePatientTimeZone.value && initialDatePatientTimeZone.value != '') {
        calculateWeeksDates();
      }
    });

    watchEffect(async () => {
      if (cgmWeeksDates.value && cgmWeeksDates.value.length >= numWeeks.value) {
        await updateWeeksStatistics();
      } else {
        setTimeout(() => {
          // the watcher is called twice when there's data in the medical test/patient (not sure about the specifics of this tbh),
          // without and with week dates, and
          // is not called when the patient has no data and the test is canceled. So this is an escape hatch for a very
          // specific case that I don't know if can really actually happen, to avoid the spinner keep spinning forever
          // when there's a canceled test with a newly created patient with no data
          isLoadingPage.value = false;
        }, 2000);
      }
    });

    function getStatistics(dateStartPatientTz: string | undefined, dateEndPatientTz: string | undefined) {
      if (!dateStartPatientTz || !dateEndPatientTz) {
        return;
      }

      return measurementService.findStatisticsCgm(
        medicalTestId,
        patientId,
        dateFormat.timezoneToUtc(dateStartPatientTz, patientTimeZone.value),
        dateFormat.timezoneToUtc(dateEndPatientTz, patientTimeZone.value),
      );
    }

    async function updateWeeksStatistics() {
      if (cgmWeeksDates.value) {
        statisticsWeek1.value = await getStatistics(cgmWeeksDates.value[0].dateStart, cgmWeeksDates.value[0].dateEnd);
        statisticsWeek2.value = await getStatistics(cgmWeeksDates.value[1].dateStart, cgmWeeksDates.value[1].dateEnd);

        if (statisticsWeek1.value instanceof ServiceError || statisticsWeek2.value instanceof ServiceError) {
          isLoadingWeeklyDataErrorful.value = true;
        }
        isLoadingPage.value = false;
      }
    }
    const patientTimezone = computed(() => storeCGM.getMedicalTestSelectedUserTimezone);
    async function changeViewToSpecificDay(selectedUtcDay: string) {
      moment.locale(i18n.global.locale.value);
      let dateStart = dateFormat.startOfCertainDay(
        moment(dateFormat.utcToTimezone(selectedUtcDay, patientTimezone.value)).format('YYYY-MM-DDTHH:mm:ss'),
      );
      let dateEnd = dateFormat.endOfCertainDay(
        moment(dateFormat.utcToTimezone(selectedUtcDay, patientTimezone.value)).format('YYYY-MM-DDTHH:mm:ss'),
      );
      await onDayChanged(dateStart, dateEnd);
      selectedView.value = ViewOption.DAILY;
    }

    return {
      ViewOption,
      medicalTest,
      OverviewOption,
      statisticsWeek1,
      statisticsWeek2,
      statisticsSelectedDay,
      eventsSelectedDay,
      selectedView,
      selectedOvewviewButton,
      viewOptions,
      numDays,
      selectedStartDate,
      // medicalTestInitialDate,
      medicalTestId,
      initialDatePatientTimeZone,
      isMedicalTestStateStarted,
      isLoadingPage,
      isLoadingDailyData,
      isLoadingDailyDataErrorful,
      isLoadingWeeklyDataErrorful,
      isEmptyDataForTheDay,
      isMedicalTestCanceled,
      isMedicalTestCanceledAndEmpty,
      router,
      cancellationDay,
      onDayChanged,
      onChangeSelectedView,
      changeViewToSpecificDay,
    };
  },
});
