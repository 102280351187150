import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "l-row--valign" }
const _hoisted_2 = { class: "chart" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_CgmGlucoseChartVue = _resolveComponent("CgmGlucoseChartVue")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('cgmDetail.day-glucose')), 1),
      _createVNode(_component_Dropdown, {
        modelValue: _ctx.filter,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filter) = $event)),
        class: "c_row__filter",
        options: _ctx.filterOptions,
        "option-label": "label",
        "option-value": "value",
        placeholder: "Filtrar evento"
      }, null, 8, ["modelValue", "options"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_CgmGlucoseChartVue, {
        chartId: "day-glucose-chart",
        serie: _ctx.glucoseSerie,
        events: _ctx.filteredEvents,
        chartStartEndDates: _ctx.chartStartEndDates,
        onAnnotationEventClick: _ctx.handleAnnotationEventClick
      }, null, 8, ["serie", "events", "chartStartEndDates", "onAnnotationEventClick"])
    ])
  ], 64))
}